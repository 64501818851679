import { getBookedDates } from "./requestBoatData";

export const nextAvailableDate = async (url_name) => {
  return getBookedDates(url_name).then((res) => {
    if (res === undefined || res.length < 1) return;
    const lastDate = res[0].split(" - ")[1];
    let av = new Date(lastDate);
    new Date(lastDate).getDay() === 6
      ? (av = new Date(lastDate))
      : (av = new Date(
          av.setDate(
            new Date(lastDate).getDate() + (6 - new Date(lastDate).getDay())
          )
        ));
    const avstring = `${av.getDate()}/${av.getMonth() + 1}/${av.getFullYear()}`;
    return avstring;
  });
};
