export function discountCalculator(reservelength, discounts) {
  const threshold = discounts.length;
  if (reservelength >= threshold) {
    if (threshold === 2) {
      return discounts[1];
    } else if (threshold > 2) {
      return discounts[threshold - 1];
    } else {
      return discounts[0];
    }
  }
  return discounts[0];
}
